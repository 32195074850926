$(document).ready(function() {
  var slash = '/';

  function setLanguage(alias, name) {
    $('.js-language-item').removeClass('active');
    $('.js-selected-value').text(alias);
    $('.js-language-item[data-alias=' + alias +']').addClass('active');
    $('.js-set-language').text(name);
  };

  function activeUrlByLang(lang) {
    var pathName = window.location.pathname;
    if (!pathName) {
      return;
    }

    var paths = pathName.split(slash);

    var index = paths.findIndex(function(value, index) {
      return value === window.lang;
    });

    paths[index] = lang;
    window.lang = lang;

    window.location.pathname = paths.join(slash);
  }

  function activeDefaultLanguage() {
    var $langElDefault = $('[data-default=true]');

    if (!$langElDefault.length) {
      $langElDefault = $('.js-language-item').first();
    } else {
      $langElDefault = $langElDefault.first();
    }

    var alias = $langElDefault.data('alias');
    var lang = $langElDefault.data('value');
    var name = $langElDefault.text();

    $('.js-set-language').text(name);

    $('.js-selected-value').text(alias);

    $('.js-language-item[data-alias=' + alias +']').addClass('active');

    window.lang = lang;
  }

  $('.js-language').click(function() {
    if (!window.onDesktopScreen()) {
      $(this).toggleClass('dropdown-active');
      $(this).find('.js-display-mobile').toggleClass('show');
    }
  });

  $('.js-language-item').click(function() {
    var alias = $(this).data('alias');
    var lang = $(this).data('value');
    var name = $(this).text();

    setLanguage(alias, name);
    activeUrlByLang(lang);
  });

  $('.js-language').hover(function(e) {
    if (window.onDesktopScreen()) {
      $(this).addClass('dropdown-active');
      $(this).find('.language__menu').stop(true, true).slideDown(250);
    }
  }, function() {
    if (window.onDesktopScreen()) {
      $(this).removeClass('dropdown-active');
      $(this).find('.language__menu').stop(true, true).slideUp(0);
    }
  });

  activeDefaultLanguage();
});
